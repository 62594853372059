<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
function isMobileDevice() {
    return /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

window.onload = function () {
    if (isMobileDevice()) {
        window.location.href = 'https://m.marscoin.site';
    }
};

export default {
    created() {
        document.title = this.$t('title');
    }
}
</script>

<style lang="scss">
* {
    margin: 0px;
    padding: 0px;
}

html,
body {
    background: black;
}

a {
    text-decoration: none;
    color: #FFFFFF;
}

button {
    background: none;
    border: none;
}
</style>
