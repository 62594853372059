import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    redirect: '/home',
    component: () => import('@/views/index.vue'),
    children:[
      {
        path: '/home',
        name:'home',
        component: () => import('@/views/indexViews/home.vue'),
      },
      {
        path: '/blurb',
        name:'blurb',
        component: () => import('@/views/indexViews/blurb.vue'),
      },
      {
        path: '/market',
        name:'market',
        component: () => import('@/views/indexViews/market.vue'),
      },
      {
        path: '/join',
        name:'join',
        component: () => import('@/views/indexViews/join.vue'),
      },
      {
        path: '/login',
        name:'login',
        component: () => import('@/views/indexViews/login.vue'),
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
