import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedstate from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',
    lang: 'zh_ch'
  },
  getters: {
    token(state) {
      return state.token
    }
  },
  mutations: {
    setToken(state, data) {
      state.token = data.token
    },
    setLang(state, data) {
      state.lang = data.lang
    }
  },
  actions: {},
  modules: {},
  plugins: [
    createPersistedstate({
      key: 'marsCoin', // 存数据的key名   自定义的  要有语义化
      // paths: ['loginModule'] // 要把那些模块加入缓存
    })
  ]

})