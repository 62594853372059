import {
    Message,
    MessageBox,
    Notification,
    Button,
    Carousel,
    carouselItem,
    image,
    input,
    select,
    option

} from 'element-ui'
const element = {
    install: function (Vue) {
        Vue.use(Button)
        Vue.use(Carousel)
        Vue.use(carouselItem)
        Vue.use(image)
        Vue.use(input)
        Vue.use(select)
        Vue.use(option)



        Vue.prototype.$message = Message;
        Vue.prototype.$confirm = MessageBox.confirm;
        Vue.prototype.$alert = MessageBox.alert;
        Vue.prototype.$notify = Notification;

    }
}

export default element